<template>
  <section>
    <div class="my-6 d-block text-center text-sm-left">
      <span class="text-h6 secondary--text"> Usuarios </span>
    </div>
    <v-tabs
      :show-arrows="$vuetify.breakpoint.xs"
      align-with-title
      background-color="bgMinsal"
      v-model="selected_tab_var"
      class="v-step-0"
      transition
    >
      <template v-for="tab in tabs_var">
        <v-tab
          class="text-no-style mx-0"
          :key="tab.id"
          v-if="tab.show"
          @click="selectTabFtn(tab.id)"
        >
          <v-icon>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>

    <v-tabs-items v-model="selected_tab_var" touchless>
      <template v-for="tab in tabs_var">
        <v-tab-item :key="tab.id" v-if="tab.show">
          <component
            :ref="`tab-${tab.id}`"
            class="my-6 v-step-3"
            :is="tab.component"
            :creado="tab.creado"
            :reporte = "tab.reporte"
          />
        </v-tab-item>
      </template>
    </v-tabs-items>
  </section>
</template>

<script>
import TableSolicitudesComponent from "./components/TableSolicitudesComponent.vue";
import TableUsuariosComponent from "./components/TableUsuariosComponent.vue";
import SolicitudesTitularComponent from "./components/SolicitudesTitularComponent.vue";
import AdminSupervisoresComponent from "./components/AdminSupervisoresComponent.vue";
import AdminSolicitudUsuariosComponent from "./components/AdminSolicitudUsuariosComponent.vue";
import { mapState } from "vuex";

export default {
  name: "mainUsuariosView",

  components: {
    TableSolicitudesComponent,
    TableUsuariosComponent,
    SolicitudesTitularComponent,
    AdminSupervisoresComponent,
  },

  data: () => ({
    tabs_var: [],
    selected_tab_var: 0,
    currentStep: 0,
    guiaCompletada: false,
  }),

  computed: {
    ...mapState("utils", ["rutas"]),
    ...mapState(["selectedUnidad"]),
    ...mapState({ userInfo: "userInfo" }),
    ...mapState("agregarInsumo", ["getTour"]),
  },

  methods: {
    selectTabFtn(id) {
      // HACK: Esta funcion funciona para forzar la la peticion inicial de los componentes, por lo que se debe mejorar, verificar v-for
      if (this.$refs?.[`tab-${id}`] != undefined) {
        this.$refs?.[`tab-${id}`][0]?.initCreated();
      }
    },

    getInfo() {
      const info = JSON.parse(localStorage.getItem("StepInfo"))
        ? JSON.parse(localStorage.getItem("StepInfo"))
        : null;
      const ruta = this.$router.currentRoute.path.split("/");
      if (info) {
        for (let value of Object.keys(info)) {
          if (ruta[1] == value) {
            if (this.userInfo?.user?.id == info[value].id) {
              this.currentStep = info[value].step;
              this.$shepherd.show(this.currentStep);
            }
          }
        }
      } else {
        this.$shepherd.show(0);
      }
    },

    nextStep() {
      this.currentStep = this.currentStep + 1;
      this.$shepherd.next(this.currentStep);
      this.dataTour(this.currentStep);
    },

    backStep() {
      this.currentStep = this.currentStep - 1;
      this.$shepherd.back(this.currentStep - 1);
      this.dataTour(this.currentStep);
    },

    cancelStep() {
      this.currentStep = null;
      this.$shepherd.cancel(this.currentStep);
      this.dataTour(this.currentStep);
    },

    completeTour() {
      this.currentStep = null;
      this.$shepherd.complete(this.currentStep);
      this.dataTour(this.currentStep);
      this.postTourCompletado();
    },

    dataTour(step) {
      const ruta = this.$router.currentRoute.path.split("/");
      const stepsInfo = {
        [ruta[1]]: {
          id: this.userInfo?.user?.id,
          email: this.userInfo?.user?.email,
          step: step,
        },
      };
      localStorage.setItem("StepInfo", JSON.stringify(stepsInfo));
    },

    stepsShepherd() {
      this.$shepherd.addSteps([
        {
          id: "1",
          title: "Gestión de usuarios",
          text: "Apartado con opciones para gestionar usuarios",
          attachTo: {
            element: ".v-step-0",
            on: "bottom",
          },

          buttons: [
            {
              text: "Salir",
              action: this.cancelStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir Guía",
              action: this.cancelStep,
            },
          ],
          advanceOn: { selector: ".v-step-0", event: "click" },
        },
        {
          id: "2",
          title: "Gestión de usuarios",
          text: "Botón para solicitud usuario",
          attachTo: {
            element: ".v-step-1",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir Guía",
              action: this.cancelStep,
            },
          ],
        },
        {
          id: "3",
          title: "Gestión de usuarios",
          text: "Espacio para aplicar filtros en la búsqueda de usuarios",
          attachTo: {
            element: ".v-step-2",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir Guía",
              action: this.cancelStep,
            },
          ],
        },
        {
          id: "4",
          title: "Gestión de usuarios",
          text: "Tabla donde se muestran los registros ",
          attachTo: {
            element: ".v-step-3",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },
            {
              text: "Completar Guía",
              action: this.completeTour,
            },
          ],
        },
      ]);
      this.getInfo();
    },

    tourComplete() {
      if (this.getTour) {
        this.getTour.forEach((element) => {
          if (element.id == 2) {
            this.guiaCompletada = true;
          }
        });
      }
    },

    async postTourCompletado() {
      const param = {
        id_flujo: 2,
        //completado: true,
      };
      const response = await this.services.TourService.postTourGuiado(param);
    },

    initTabs() {
      this.tabs_var = [
        {
          id: 0,
          name: "Solicitudes",
          icon: "mdi-text-box-check",
          component: TableSolicitudesComponent,
          show: this.haveRoles([
            "ROLE_UFI",
            "ROLE_UACI",
            "ROLE_USER_ADMIN",
            "ROLE_JEFE_UNIDAD",
            "ROLE_UACI_TECNICO",
            "ROLE_TITULAR_ESTABLECIMIENTO",
          ]),
        },
        {
          id: 1,
          name: "Creación",
          icon: "mdi-account-plus",
          component: TableUsuariosComponent,
          show: this.haveRoles([
            "ROLE_USER_ADMIN",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TITULAR_ESTABLECIMIENTO",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
          ]),
          creado: true,
        },
        {
          id: 2,
          name: "Lista de usuarios",
          icon: "mdi-format-list-bulleted-square",
          reporte: true,
          component: TableUsuariosComponent,
          show: this.haveRoles([
            "ROLE_UFI",
            "ROLE_UACI",
            "ROLE_USER_ADMIN",
            "ROLE_JEFE_UNIDAD",
            "ROLE_UACI_TECNICO",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TITULAR_ESTABLECIMIENTO",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
            "ROLE_OFICIAL_CUMPLIMIENTO",
            "ROLE_AUDITOR_INTERNO_AUIN",
            "ROLE_TACOP",
          ]),
        },
        {
          id: 3,
          name: "Solicitud de titular",
          icon: "mdi-format-list-bulleted-square",
          component: SolicitudesTitularComponent,
          show: this.haveRoles([
            "ROLE_USER_ADMIN",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
          ]),
        },
        {
          id: 4,
          name: "Supervisores",
          icon: "mdi-shield-account",
          component: AdminSupervisoresComponent,
          show: this.haveRoles([
            "ROLE_USER_ADMIN",
            "ROLE_DIRECCION_COMPRAS",
          ]),
        },
        {
          id: 5,
          name: "Administrar solicitudes",
          icon: "mdi-account-group",
          component: AdminSolicitudUsuariosComponent,
          show: this.haveRoles([
            "ROLE_SUPER_ADMIN",
          ]),
        },
      ];
    },
  },
  created() {
    this.initTabs();
  },
};
</script>

<style>
.tabStyle {
  width: 100%;
}

.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>